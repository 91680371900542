@charset 'utf-8';

.cm-filter__sort {
  $this: &;

  height: 22px;
  font-size: 13px;
  color: $oldGray6;
  vertical-align: top;

  // ABC / ㄱㄴㄷ 필터 버튼
  @at-root {
    .cm-filter__tabs__translation & {
      position: relative;
      padding: 3px 0 0 6px;
      margin-right: 15px;
      font-family: $font-default;
      font-size: 12px;

      &::before {
        @include position(absolute, 50%, null, null, 0);

        display: block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: $oldGray6;
        transform: translateY(-50%);
        content: '';
      }

      // ABC
      &:first-of-type {
        letter-spacing: 0.5px;
      }

      // ㄱㄴㄷ
      &:last-of-type {
        margin: 0px;
        letter-spacing: -1.5px;
      }

      #{$this}__text {
        font-weight: bold;
      }
    }
  }

  // 브랜드 필터 '좋아요' 아이콘 버튼
  &--like {
    margin: 0 3px;

    #{$this}__svg {
      display: block;
      width: 22px;
      height: 22px;
      line-height: 0;
      transform: translateY(1px);
    }

    &__icon {
      width: 100%;
      g {
        &:first-of-type path {
          fill: transparent;
        }
        &:last-of-type {
          path {
            fill: $oldGray6;
          }
          rect {
            fill: transparent;
          }
        }
      }
    }
  }

  &__text {
    display: block;
    min-width: 30px;
  }

  // --like: 브랜드 필터 '좋아요' 버튼
  // --best: 브랜드 필터 'Best', '인기' 버튼
  // --extra: 브랜드 필터 '단독' 등등 버튼
  &--like,
  &--best,
  &--extra {
    #{$this}__text {
      width: auto;
    }
  }

  // --best: 브랜드 필터 'Best', '인기' 버튼
  &--best {
    width: 40px;
  }

  // --extra: 브랜드 필터 '단독' 등등 버튼
  &--extra {
    padding: 0 3px;
  }

  &--active {
    #{$this}__text {
      font-weight: bold;
      color: $musinsaAccent;
    }

    @at-root {
      // ABC, ㄱㄴㄷ 버튼
      .cm-filter__tabs__translation & {
        &::before {
          background-color: $musinsaAccent;
        }

        #{$this}__text {
          color: $black;
        }
      }

      // 좋아요 버튼
      .cm-filter__tabs__contents &#{$this}--like {
        g {
          &:first-of-type path {
            fill: $red1;
          }
          &:last-of-type {
            path,
            rect {
              fill: transparent;
            }
          }
        }
      }
    }
  }
}
