@charset 'utf-8';

.cm-filter__lists-text {
  $this: &;
  @include overflowScroll('x');

  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: $gray100;
  font-size: 0;

  & + & {
    border-top: 1px solid $gray300;
  }

  &__button {
    font-size: 14px;
    color: $black;

    & + #{$this}__button {
      margin-left: 20px;
    }

    &--active {
      font-weight: 700;
      color: $musinsaAccent;
    }
  }
}
