@charset 'utf-8';

.cm-filter__item {
  $this: &;

  display: flex;
  align-items: center;
  position: relative;
  height: 35px;
  padding: 0 15px;
  margin-right: 5px;
  border: 1px solid $oldGray4;
  border-radius: 17px;
  box-sizing: border-box;
  background-color: $white;

  // 필터 상단 캠페인 버튼
  &--campaign {
    // $campaign 변수의 정의는 common/filter/variables 에 있음
    @each $idx, $color in $campaigns {
      &#{$idx}::before {
        @include position(absolute, -7px, null, null, -5px);

        padding: 3px 5px 1px;
        border-radius: 8px 8px 0px;
        background-color: #{$color};
        font-family: $font-musinsa;
        font-size: 10px;
        font-weight: bold;
        line-height: 12px;
        color: $white;
        content: 'EVENT';
      }
    }
  }

  &--groupSale {
    // $campaign 변수의 정의는 common/filter/variables 에 있음

    &::before {
      @include position(absolute, -7px, null, null, -5px);

      padding: 3px 5px 1px;
      border-radius: 8px 8px 0px;
      background-color: #4cb21c;
      font-family: $font-musinsa;
      font-size: 10px;
      font-weight: bold;
      line-height: 12px;
      color: $white;
      content: 'EVENT';
    }
  }

  &--plusdelivery {
    &::before {
      width: 22px;
      height: 22px;
      margin-right: 5px;
      background-color: $black;
      mask-image: url(https://image.msscdn.net/musinsaUI/store/label/Ic22linePlusDeliveryBlack.svg?202304201542);
      mask-repeat: no-repeat;
      mask-position: center center;
      content: '';
    }
  }

  &--special {
    &::after {
      @include position(absolute, -7px, null, null, -5px);

      padding: 3px 5px 1px;
      border-radius: 8px 8px 0px;
      background-color: $blue400;
      font-family: $font-musinsa;
      font-size: 10px;
      font-weight: bold;
      line-height: 12px;
      color: $white;
      content: 'NEW';
    }
  }

  &__badge {
    @include position(absolute, -7px, null, 100%, -5px);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 16px;
    padding: 3px 5px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: $white;

    > svg {
      height: 10px;
    }

    .svg-color {
      fill: $white;
    }
  }

  &__text {
    font-family: inherit;
    font-size: 14px;
    line-height: 1;
    color: $black;
  }

  &__svg {
    width: 18px;
    margin: 1px -4px 0 3px;

    .svg-color {
      fill: #d8d8d8;
    }
  }

  // 필터 선택시
  &--active {
    border-color: $musinsaAccent;
    background-color: $musinsaAccent;

    #{$this}__text {
      color: $white;
    }

    #{$this}__arrow {
      transform: rotate(180deg);
    }

    #{$this}__svg .svg-color {
      fill: $white;
    }
  }

  // 필터의 값 선택시
  &--selected {
    #{$this}__text {
      color: $musinsaAccent;
    }

    @each $idx, $color in $campaigns {
      &#{$this}--campaign#{$idx} {
        #{$this}__text {
          color: #{$color};
        }
      }
    }

    &#{$this}--campaign {
      #{$this}__text {
        color: $musinsaAccent;
      }
    }

    &#{$this}--groupSale {
      #{$this}__text {
        color: #4cb21c;
      }
    }

    &#{$this}--plusdelivery {
      &::before {
        background-color: $musinsaAccent;
      }
    }
  }

  &:last-child() {
    margin-right: 0;
  }
}
