@charset 'utf-8';

.cm-filter__input {
  $this: &;

  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  background-color: $white;
  transition: border-color .3s ease-in-out;

  @at-root {
    // 가격 탭에서 노출
    .cm-filter__price & {
      width: 37.97%;
      vertical-align: middle;

      &__text {
        font-size: 14px;
      }
    }

    // NOTE
    // 브랜드숍, 카테고리메뉴 브랜드탭에서는 cm-filter__contents--brand class 추가
    .cm-filter__contents--brand & {
      border: 0;
      background-color: $oldGray2;
    }
  }

  &--active {
    #{$this}__button {
      opacity: 1;
      transition: opacity .3s ease-in-out;
    }
  }

  &:focus-within {
    border-color: $oldGray6;
    transition: border-color .3s ease-in-out;
  }

  &__svg {
    flex: 0 0 22px;
    margin: 0 4px 0 10px;

    path,
    polygon {
      fill: $oldGray6;
    }

    & + input[type="text"] {
      width: calc(100% + 10px);
      height: 100%;
      padding-right: 0;
      border: 0;
      font-size: 15px;
      text-indent: 0;
      transform: translateX(-10px);
    }
  }

  &__text {
    @include placeholder($oldGray6);

    appearance: none;
    flex: 1 1 100%;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background-color: transparent;
    font-size: 16px;
  }

  &__button {
    flex: 0 0 38px;
    position: relative;
    height: 38px;
    background: radial-gradient(circle, rgba(229,229,229,1) 0%, rgba(229,229,229,1) 34%, rgba(229,229,229,0) 34%, rgba(255,255,255,0) 100%);
    transition: opacity .3s ease-in-out;
    opacity: 0;

    &::before,
    &::after {
      @include position(absolute, 50%, 50%);

      display: block;
      width: 1px;
      height: 10px;
      background-color: $oldGray6;
      content: '';
    }

    &::before {
      transform: translate(50% ,-50%) rotate(45deg);
    }
    &::after {
      transform: translate(50% ,-50%) rotate(135deg);
    }
  }
}