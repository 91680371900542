.cm-loading {
  display: none;
  text-align: center;

  &--active {
    display: block;

    > svg {
      transform-origin: 50% 50%;
      animation: roundAll 1s infinite linear;
    }
  }

  &--dim-active {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .4);
    z-index: 9999;

    > svg {
      transform-origin: 50% 50%;
      animation: roundAll 1s infinite linear;
    }
  }

  &-full {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px);
  }

  &__text {
    margin-top: 16px;
    font-size: 15px;
    font-weight: 700;
    color: $black;
  }

  @at-root {
    .n-list-ranking & { // 카테고리 목록 - 이전 버전 클래스명 변경 예정
      padding: 30px 0 60px 0;
    }

    .cm-loading-wrap & { // default 간격
      padding: 100px 0;
    }

    .cm-filter__swiper & {
      padding: 30px 0;
    }
  }
}