@charset 'utf-8';

.container {
  &--fullpage {
    height: 100%;
  }

  #headerCommonLayout {
    height: 50px; // NOTE Common Layout 에서 렌더링 시 헤더 영역 확보
  }
}
.content {
  &--fullpage {
    height: calc(100% - 50px);
  }

  //무신사 페이관리
  &--paysetting {
    @include position(absolute, 50px, 0);

    width: 100%;
  }
}
