@charset 'utf-8';

@mixin font-face($family, $weight, $style, $src, $svg) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    src: url('//image.msscdn.net/musinsaUI/fonts/#{$src}.eot');
    src: local('☺'), url('//image.msscdn.net/musinsaUI/fonts/#{$src}.eot?#iefix') format('embedded-opentype'),
      url('//image.msscdn.net/musinsaUI/fonts/#{$src}.woff2') format('woff2'),
      url('//image.msscdn.net/musinsaUI/fonts/#{$src}.woff') format('woff'),
      url('//image.msscdn.net/musinsaUI/fonts/#{$src}.ttf') format('truetype'),
      url('//image.msscdn.net/musinsaUI/fonts/#{$src}.svg##{$svg}') format('svg');
  }
}

/*
  NOTE 채널별 컬러값 적용
  사용 예시
  @include channelColor(wusinsa, background-color, $wusinsaAccent);
*/
@mixin channelColor($channel, $attribute, $color) {
  $channels: (wusinsa, mensinsa);
  $attributes: (background-color, background-image, color, border-color, fill);

  @at-root {
    .#{$channel} &,
    .wrapper-#{$channel} & {
      // TODO 채널 컬러 변경 PHP 전체 개선 후 삭제
      #{$attribute}: $color;
    }
  }
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  // NOTE sticky 시 사파리 하위버전 대응
  @if ($position == 'sticky') {
    position: -webkit-sticky;
  }

  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin ellipsis($line: 1) {
  @if ($line == 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-line-clamp: $line; /* ellipsis line */
    -webkit-box-orient: vertical;
    /* webkit 엔진을 사용하지 않는 브라우저를 위한 속성. */
    /* height = line-height * line = 1.2em * 3 = 3.6em  */
    /* line-height: 1.2em;
    height: 3.6em; */
  }
}

@mixin safearea($attribute, $value) {
  $attributes: (height, bottom, padding-bottom, margin-bottom);
  #{$attribute}: $value;
  #{$attribute}: calc(env(safe-area-inset-bottom) + #{$value});
  #{$attribute}: calc(constant(safe-area-inset-bottom) + #{$value});
}

@mixin blindScroll {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes roundAll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin resetFont {
  // 스토어 코드의 reset 상쇄 필요 시에만 적용
  font-family: $font-default;
  font-size: 14px;
  line-height: 1.5;
}

@mixin overflowScroll($direction) {
  overflow: hidden;
  overflow-#{$direction}: auto;
  white-space: nowrap;

  @include blindScroll;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}
/*
  NOTE 
  사용예시 CMainRankingBrand.scss 참조
*/
@mixin changeArrow($state) {
  @if $state == 'up' {
    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      background-color: transparent;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent $error transparent;
      content: '';
    }
  } @else if $state == 'down' {
    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      background-color: transparent;
      border-width: 4px 4px 0 4px;
      border-color: $blue600 transparent transparent transparent;
      content: '';
    }
  } @else if $state == 'same' {
    &::before {
      width: 4px;
      height: 2px;
      background-color: $oldGray5;
      content: '';
    }
  }
}

@mixin swiperIndicator($bulletColor, $bottom) {
  @if $bulletColor == 'black' {
    $bulletColor: (
      $gray400: $black,
    );
  } @else if $bulletColor == 'white' {
    $bulletColor: (
      rgba(255, 255, 255, 0.5): $white,
    );
  }

  &.swiper-pagination-bullets {
    position: absolute;
    bottom: $bottom;
    line-height: 0;
    text-align: center;
    transition: 0.3s opacity;
    z-index: 10;

    @each $color, $activeColor in $bulletColor {
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0 3px;
        border-radius: 6px;
        background-color: $color;
        opacity: 1;

        &-active {
          background-color: $activeColor;
        }
      }
    }
  }
}
