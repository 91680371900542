.cm-util-option {
  position: relative;
  margin-left: 5px;

  &__button {
    display: flex;
    height: 31px;
    padding: 5px;
    line-height: 1.5;
    color: $black;

    > svg {
      vertical-align: top;
    }

    &--check {
      color: $oldGray6;

      .svg-color {
        fill: $oldGray6;
      }

      &.active {
        color: $black;

        .svg-color {
          fill: $musinsaAccent;
        }
      }

      > svg {
        margin: 1px 2px 0 0;
      }
    }

    &--layer {
      > svg {
        margin: 1px 0 0 2px;
      }

      &.active > svg {
        transform: rotate(180deg);
      }
    }

    &--direct > svg {
      margin: -1px 0 0 2px;
    }

    &--icon {
      margin-left: -3px;

      > svg {
        margin: -1px 0 0 0;
      }
    }
  }

  &__layer {
    .active + & {
      display: block;
      animation: fadeIn 0.15s;
    }

    display: none;
    min-width: 110px;
    position: absolute;
    right: 5px;
    top: 100%;
    z-index: 400;
    padding: 9px 0;
    margin-top: -3px;
    border: 1px solid #e5e5e5;
    background-color: $white;

    > button {
      display: block;
      overflow: hidden;
      width: 100%;
      padding: 6px 15px;
      color: $oldGray6;
      text-align: left;
      white-space: nowrap;
      box-sizing: border-box;

      > svg {
        float: right;
        margin-right: -5px;

        .svg-color {
          fill: $oldGray6;
        }
      }

      &.active {
        color: $black;

        > svg {
          transform: rotate(180deg);

          .svg-color {
            fill: $black;
          }
        }
      }
    }

    &--add {
      position: static;
      margin: -2px 0 -9px 0;
      background-color: #f9f9f9;
      border: none;
    }
  }

  &__recommend {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 6px 15px;
    box-sizing: border-box;

    &-button {
      flex: 0 0 auto;
      width: auto;
      padding: 0;
      color: $oldGray6;

      &.active {
        color: $black;
      }
    }

    &-ico {
      margin-left: 2px;

      > button {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    &__tooltip {
      @include tooltip($direction: top, $top: -53px, $right: 11px, $width: 176px, $arrowRight: 8px);
    }
  }
}
