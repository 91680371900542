@charset 'utf-8';

.cm-filter {
  $this: &;

  background-color: $white;

  &__header {
    position: relative;
    height: 55px;
    box-sizing: border-box;
    font-size: 0;

    &__content {
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      position: relative;
      height: 55px;
      padding: 10px 10px 80px;
      box-sizing: border-box;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      &::after {
        flex: 0 0 15px;
        height: 15px;
        content: '';
      }

      @at-root .cm-filter--sticky & {
        padding-bottom: 0;
      }
    }
  }

  &__contents {
    padding: 10px 0;
    box-sizing: border-box;
    background-color: $oldGray1;

    &--text {
      padding: 0;
    }

    // NOTE
    // 브랜드숍, 카테고리메뉴 브랜드탭에서는 cm-filter__contents--brand class 추가
    &--brand {
      padding: 5px 0 0;
      background-color: #fff;
    }
  }

  &__swiper {
    overflow: hidden;
  }

  &__lists {
    padding: 3px 15px 0;
    box-sizing: border-box;
    font-size: 0;
  }

  // 검색 필터
  &__search {
    margin: 10px 15px;
  }

  // 가격 필터
  &__price {
    margin: 7px 15px 0;
    font-size: 0;

    &__unit {
      display: inline-block;
      width: 5.2%;
      font-size: 14px;
      color: $oldGray6;
      text-align: center;
      vertical-align: middle;
    }
  }

  // TODO [UI-3566] 배포 완료 되면 삭제
  // 브랜드 필터 tabs
  &__tabs {
    position: relative;
    display: flex;
    padding-top: 5px;
    margin: 0 15px;

    // 브랜드 필터랑 하단 컨텐츠랑 바로 붙어 있다면 컨텐츠에 간격을 준다.
    & + .cm-filter__swiper {
      margin-top: 7px;
    }

    &::after {
      @include position(absolute, 0, 0);

      display: block;
      width: 36px;
      height: 30px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f9f9f9 90%, #f9f9f9 100%);
      content: '';
    }

    &__translation {
      flex: 0 0 auto;
      position: relative;
      height: 29px;
      padding-right: 15px;
      font-size: 0;

      &::after {
        @include position(absolute, 4px, 0);

        width: 1px;
        height: 14px;
        background-color: #e5e5e5;
        content: '';
      }
    }

    &__contents {
      @include overflowScroll('x');

      flex: 1 1 100%;
      padding: 0 25px 7px 9px;
      font-size: 0;
    }
  }
  // TODO [UI-3566] 배포 완료 되면 삭제 끝

  // 브랜드 필터 tabs-v2
  &__tabs-v2 {
    @include overflowScroll('x');

    display: flex;
    position: relative;
    padding: 4px 10px 14px;

    @at-root {
      // NOTE 해당 영역을 변경 한다면 해당 코드 삭제 (하단 메뉴바 브랜드 카테고리)
      .filter-brandshop & {
        padding-top: 0;
      }
    }

    &__static {
      font-size: 0;
    }

    &__contents {
      display: flex;
      align-items: center;
      padding-right: 15px;
      font-size: 0;
    }
  }

  // 기간 필터
  &__period {
    margin: 10px 15px;
    font-size: 0;
  }

  // 초성 필터
  &__initial {
    padding: 5px 0;

    &__tab-wrap {
      display: flex;
      overflow: hidden;
      margin: 0 15px;
      border: 1px solid $oldGray4;
      border-radius: 6px;
      background-color: $oldGray2;
    }

    &__tab {
      flex: 1 1;
      height: 40px;
      font-size: 13px;
      font-weight: 400;
      color: $oldGray6;

      &--active {
        margin: 0 -1px;
        border-left: 1px solid $oldGray4;
        border-right: 1px solid $oldGray4;
        border-radius: 4px;
        box-sizing: border-box;
        background-color: $white;
        font-weight: 600;
        color: $black;
      }
    }

    &__sorting-wrap {
      display: none;
      flex-wrap: wrap;
      padding: 0 10px;

      &--active {
        display: flex;
      }
    }

    &__sorting {
      width: calc(100% / 7);
      margin-top: 15px;
      text-align: center;
    }

    &__button {
      width: 34px;
      height: 34px;
      border: 1px solid #eaeaea;
      border-radius: 100%;
      box-sizing: border-box;
      background-color: $oldGray4;
      font-size: 15px;
      font-weight: 400;
      color: $oldGray6;

      &--active {
        border-color: $musinsaAccent;
        background-color: $musinsaAccent;
        color: $white;
      }
    }
  }

  // 필터 선택 결과
  &__result {
    position: relative;
    border-top: 1px solid $oldGray3;
    box-sizing: border-box;
    transition: transform 0.4s;

    &__contents {
      @include overflowScroll('x');

      display: flex;
      align-items: center;
      width: calc(100% - 50px);
      height: 50px;
      padding: 12px 0 12px 15px;
      box-sizing: border-box;
    }

    &__button {
      @include position(absolute, 0, 0);

      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
    }
  }

  // 결과 없을때
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 205px;
    text-align: center;
    color: $oldGray7;
  }

  // 태그
  &__tag {
    @include overflowScroll('x');

    padding: 13px 15px 12px;
    border-bottom: 1px solid $oldGray3;
    font-size: 0;
  }

  // TODO [UI-3566] 배포 완료 되면 삭제
  &__tooltip {
    @include position(absolute, 100%, 50%);

    display: none;
    border-radius: 4px;
    background-color: $musinsaAccent;
    transform: translateX(50%);
    animation: fadeOut 1s cubic-bezier(0.72, -0.01, 0.26, 1) 3s 1 alternate forwards;
    z-index: 10;

    &::before {
      @include position(absolute, -4px, 50%);

      width: 5px;
      height: 5px;
      background-color: $musinsaAccent;
      transform: rotate(45deg) translateX(50%);
      content: '';
    }

    &__text {
      position: relative;
      padding: 7px 34px 6px;
      font-size: 11px;
      color: $white;
      white-space: nowrap;

      &::before,
      &::after {
        display: block;
        width: 12px;
        height: 9px;
        background-color: $white;
        clip-path: polygon(
          4px 0,
          0 4.5px,
          4px 9px,
          5px 8.5px,
          1.5px 4.5px,
          5px 0.5px,
          4px 0px,
          10px 0,
          6px 4.5px,
          10px 9px,
          11px 8.5px,
          7.5px 4.5px,
          11px 0.5px,
          10px 0px
        );
        content: '';
      }

      @mixin animation($name) {
        animation: $name 0.6s cubic-bezier(0.72, -0.01, 0.26, 1) 1s 3 alternate backwards;
      }

      &::before {
        @include position(absolute, 50%, null, null, 0);
        @include animation(filterTooltipBounceLeft);

        transform: translate(11px, -50%);
      }

      &::after {
        @include position(absolute, 50%, 0);
        @include animation(filterTooltipBounceRight);

        transform: translate(-11px, -50%) rotate(180deg);
      }
    }

    &--active {
      display: inline-block;
    }
  }
  // TODO [UI-3566] 배포 완료 되면 삭제 끝

  // tooltip
  &__tooltip-v2 {
    @include position(absolute, calc(100% + 8px), 50%);

    display: none;
    border-radius: 4px;
    background-color: $musinsaAccent;
    transform: translateX(50%);
    animation: fadeOut 1s cubic-bezier(0.72, -0.01, 0.26, 1) 3s 1 alternate forwards;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    z-index: 10;

    &::before {
      @include position(absolute, -4px, 50%);

      width: 5px;
      height: 5px;
      background-color: $musinsaAccent;
      transform: rotate(45deg) translateX(50%);
      content: '';
    }

    &__text {
      position: relative;
      padding: 7px 8px 6px;
      font-size: 11px;
      color: $white;
      white-space: nowrap;
    }

    &--active {
      display: inline-block;
    }
  }

  &__range {
    &__title {
      display: flex;
      position: relative;
      align-items: center;
      height: 36px;
      font-size: 14px;
      z-index: 10;
    }

    &__contents {
      height: 39px;
      padding-top: 6px;
      margin-top: 8px;
      box-sizing: border-box;
    }

    &__toggle {
      display: block;
      width: 100%;
      height: 100%;
      color: $black;
      text-align: left;
      user-select: none;

      &--active {
        color: $musinsaAccent;
      }
    }

    &__button {
      @include buttonOutlined(
        $isBlock: true,
        $height: 40px,
        $margin: 12px 0 10px,
        $borderColor: #ccc,
        $borderRadius: 4px,
        $background: transparent,
        $color: $black,
        $isPressed: false
      );

      user-select: none;
    }
  }

  @keyframes filterTooltipBounceLeft {
    0% {
      transform: translate(11px, -50%);
    }
    50% {
      transform: translate(8px, -50%);
    }
    100% {
      transform: translate(11px, -50%);
    }
  }

  @keyframes filterTooltipBounceRight {
    0% {
      transform: translate(-11px, -50%) rotate(180deg);
    }
    50% {
      transform: translate(-8px, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-11px, -50%) rotate(180deg);
    }
  }

  &--sticky {
    @include position(sticky, null, 0, null, 0);

    height: 55px;
    z-index: 490;

    #{$this}__header {
      border-bottom: 1px solid #e5e5e5;
      background-color: $white;
      z-index: 20;
    }

    #{$this}__body {
      height: 0;
    }

    #{$this}__contents {
      position: relative;
      z-index: 10;
    }

    #{$this}__result {
      background-color: $white;
      border-top: none;
      border-bottom: 1px solid #e5e5e5;

      &--hide {
        transform: translateY(calc(-100%));
      }
    }

    @at-root .brand-index & {
      top: 93px;
    }
  }
}
