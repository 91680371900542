@charset 'utf-8';

// ANCHOR label

// label fontsize function
@function fontSize($height) {
  @return ceil($height / 2.3);
}

// label
@mixin label(
  $display: inline-flex,
  $justifyContent: null,
  $alignItems: null,
  $width: null,
  $height: 26px,
  $padding: 0 ceil($height / 4.4),
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: null,
  $fontFamily: $font-musinsa,
  $fontSize: ceil($height / 2.3),
  $fontWeight: null,
  $color: $white,
  $lineHeight: null,
  $letterSpacing: -0.5px,
  $verticalAlign: top
) {
  @if $display == 'inline-flex' or $display == 'flex' {
    $alignItems: center;
    $justifyContent: center;
  }

  @if $borderColor {
    border: 1px solid $borderColor;
  }

  display: $display;
  justify-content: $justifyContent;
  align-items: $alignItems;
  width: $width;
  height: $height;
  padding: $padding;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  letter-spacing: $letterSpacing;
  vertical-align: $verticalAlign;

  & [class$='__sub'] {
    padding-left: 5px;
  }
}

@function buttonPadding($height) {
  @return floor($height / 2.8);
}

// ANCHOR button
@mixin button(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: null,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: null,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: null,
  $pressedBackground: null,
  // disable
    $isDisabled: null,
  $disabledColor: null,
  $disabledBackground: null,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    // a tag 이고 $isDisabled 상태가 있다면 preesedBackground 를 background 로 설정한다.
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonContained
@mixin buttonContained(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: null,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $white,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: null,
  $pressedBackground: null,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  @if $fontWeight == null {
    @if $height >= 50 {
      $fontWeight: bold;
    } @else {
      $fontWeight: normal;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonContainedPrimary
@mixin buttonContainedPrimary(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $black,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $white,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: $oldGray8,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  @if $fontWeight == null {
    @if $height >= 50 {
      $fontWeight: bold;
    } @else {
      $fontWeight: normal;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonContainedAccent
@mixin buttonContainedAccent(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $blue700,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $white,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: #0064d4,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  @if $fontWeight == null {
    @if $height >= 50 {
      $fontWeight: bold;
    } @else {
      $fontWeight: normal;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonContainedSecondary
@mixin buttonContainedSecondary(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $oldGray6,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $white,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: $oldGray7,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  @if $fontWeight == null {
    @if $height >= 50 {
      $fontWeight: bold;
    } @else {
      $fontWeight: normal;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonContainedWusinsa
@mixin buttonContainedWusinsa(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: null,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $wusinsaAccent,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $white,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: $wusinsaAccent,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: null,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  @if $fontWeight == null {
    @if $height >= 50 {
      $fontWeight: bold;
    } @else {
      $fontWeight: normal;
    }
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonOutlined
@mixin buttonOutlined(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: $black,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $white,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $black,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: $oldGray7,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: null,
  $disabledBorderColor: $oldGray5,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  @if $color == null {
    $color: $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonOutlinedAccent
@mixin buttonOutlinedAccent(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: $blue700,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $white,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $blue700,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: #cfe6ff,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: $oldGray5,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR buttonOutlinedWusinsa
@mixin buttonOutlinedWusinsa(
  $display: null,
  $alignItems: center,
  $justifyContent: center,
  $width: null,
  $height: 50px,
  $padding: null,
  $margin: null,
  $borderColor: $wusinsaAccent,
  $borderRadius: null,
  $boxSizing: border-box,
  $background: $white,
  $fontFamily: null,
  $fontSize: 14px,
  $fontWeight: null,
  $color: $wusinsaAccent,
  $textAlign: center,
  $verticalAlign: null,
  $isBlock: false,
  // a 인지 button 인
    $isA: false,
  // presse
    $isPressed: true,
  $pressedBackground: $wusinsaAccent,
  // disable
    $isDisabled: true,
  $disabledColor: $oldGray6,
  $disabledBackground: $oldGray4,
  $disabledBorderColor: $oldGray5,
  // ico
    $iconLocation: null,
  $iconMargin: null,
  $iconColor: $color,
  $iconDisabledColor: $disabledColor
) {
  @if $borderColor != null {
    border: 1px solid $borderColor;
  }

  // $display 속성이 null(기본값) 이면
  @if $display == null {
    // $isBlock 속성이 true 면
    @if $isBlock {
      $display: flex;
    } @else {
      $display: inline-flex;
    }
    // $display 속성에 의해 flex option 이 랜더링 됨
    align-items: $alignItems;
    justify-content: $justifyContent;
  }

  // $width 가 null(기본값) 이면
  // $width 를 따로 설정하지 않으면 $isBlock 에 따라 width 값이 정해진다.
  @if $width == null {
    // $isBlock 이 true 면
    @if $isBlock {
      $width: 100%;
    } @else {
      $width: auto;
    }
  }

  @if $padding == null {
    $padding: 0 floor($height / 2.8);
  }

  // $color 가 null(기본값) 이면
  // $iconColor 에 $black 을 할당한다.
  // 이렇게 하는 이유는
  // reset.scss 에 button 에 color property 가 명시되어 있지 않음.
  // 따라서 브라우저는 button 에 브라우저 기본 button 색인 $black 을 랜더링 시켜줌
  @if $color == null {
    $iconColor: $black;
  }

  // $iconMargin 은 $iconLocation 이 null 이 아니여야 유효함
  // 따라서, $iconLocation 부터 체크한다.
  @if $iconLocation {
    @if $iconLocation == 'left' {
      @if $iconMargin == null {
        $iconMargin: 0 2px 0 -5px;
      }
    } @else {
      flex-direction: row-reverse;
      @if $iconMargin == null {
        $iconMargin: 0 -5px 0 2px;
      }
    }
  }

  display: $display;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  border-radius: $borderRadius;
  box-sizing: $boxSizing;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  vertical-align: $verticalAlign;

  // icon 이 있을때
  @if $iconLocation {
    & {
      > span {
        font-size: inherit;
        line-height: 1;
      }

      > svg {
        margin: $iconMargin;
      }

      .svg-color {
        fill: $iconColor;
      }
    }
  }

  // disabled 상태가 있는 버튼이면
  @if $isDisabled {
    @if $isA {
      @at-root {
        &[class$='--disabled'] {
          border-color: $disabledBorderColor;
          background: $disabledBackground;
          color: $disabledColor;

          &:active:not(disabled) {
            border-color: $disabledBorderColor;
            background: $disabledBackground;
            color: $disabledColor;
          }

          // icon 이 있는 버튼이면
          @if $iconLocation {
            .svg-color {
              // icon 의 disabledColor 가 따로 존재한다면
              @if $iconDisabledColor {
                fill: $iconDisabledColor;
              } @else {
                fill: $disabledColor;
              }
            }
          }
        }
      }
    } @else {
      &:disabled {
        // $disabledBacgkround 에 값이 있으면
        @if $disabledBackground != $background {
          background: $disabledBackground;
        }

        border-color: $disabledBorderColor;
        color: $disabledColor;
        cursor: default;

        // icon 이 있는 버튼이면
        @if $iconLocation {
          .svg-color {
            // icon 의 disabledColor 가 따로 존재한다면
            @if $iconDisabledColor {
              fill: $iconDisabledColor;
            } @else {
              fill: $disabledColor;
            }
          }
        }
      }
    }
  }

  // :active 가 있고 :active 전용 background 가 있다면
  @if $isPressed and $pressedBackground {
    &:active {
      background: $pressedBackground;
    }
  }
}

// ANCHOR rating
@mixin rating(
  $width: null,
  $height: 30px,
  $textPadding: null,
  $textAlign: left,
  $backgroundWidth: null,
  $translateY: -15%
) {
  $width: ($height + 1px) * 5;

  display: inline-flex;
  align-items: baseline;
  overflow: hidden;
  height: $height;
  color: $yellow1;
  font-family: $font-musinsa;
  text-align: $textAlign;
  line-height: 1;

  &__unit {
    overflow: hidden;
    width: $width;
    height: 100%;
    background: url('../img/common/ic-star-off.svg?v=#{timestamp()}') no-repeat;
    background-position: left top;
    background-size: $width 100%;
  }

  &__active {
    display: block;
    width: $width;
    height: 100%;
    background: url('../img/common/ic-star-on.svg?v=#{timestamp()}') no-repeat;
    background-position: left top;
    background-size: $width 100%;

    &--single {
      width: $height;
    }
  }

  &__text {
    // 높이를 4로 나눈 값
    $fontSize: ceil($height * 0.85);

    // height: 100%;
    padding: $textPadding;
    margin-left: ceil($height / 4);
    box-sizing: border-box;
    font-family: $font-musinsa;
    font-size: $fontSize;
    // line-height: 1;
    color: $yellow1;
    transform: translateY(#{$translateY});
  }
}

// ANCHOR like
@mixin like(
  $display: inline-flex,
  $alignItems: center,
  $justifyContent: center,
  $flexDirection: null,
  $width: auto,
  $height: 30px,
  $textPadding: null,
  $fontFamily: $font-musinsa,
  $fontSize: null,
  $color: null,
  $textmargin: null,
  $direction: null,
  $iconSize: null,
  $iconMargin: null,
  $iconLineColor: null,
  $iconStencilColor: null,
  $iconSolidColor: $red1
) {
  @if $fontSize == null {
    $fontSize: ceil($height * 0.65);
  }
  @if $iconMargin == null {
    @if $direction == 'reverse' {
      $iconMargin: 0 0 0 ceil($height / 14);
    } @else if $direction == 'column' {
      $iconMargin: 0;
    } @else {
      $iconMargin: 0 ceil($height / 14) 0 0;
    }
  }
  @if $color == null {
    $color: $red1;
  }
  @if $direction == 'reverse' {
    $flexDirection: row-reverse;
  } @else if $direction == 'column' {
    $flexDirection: column;
    $iconMargin: 0;
    $iconSize: $height;
    $height: auto;
  }

  display: $display;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
  overflow: hidden;
  width: $width;
  height: $height;
  font-family: $fontFamily;
  font-size: $fontSize;
  color: $color;
  line-height: $fontSize + 2px;
  white-space: nowrap;
  vertical-align: top;

  &__unit {
    @if $direction == 'column' {
      width: $iconSize;
      height: $iconSize;
    } @else {
      width: $height;
      height: $height;
    }
    margin: $iconMargin;
    vertical-align: top;
    shape-rendering: auto;
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  .svg-line {
    stroke: $iconLineColor;
  }

  .svg-stencil {
    stroke: $iconStencilColor;
  }

  .svg-solid {
    fill: $iconSolidColor;
  }

  &__text {
    padding: $textPadding;
    font-family: inherit;
    line-height: 1.5;
  }

  // 좋아요 클릭시 활성화 클래스
  &--active {
    color: $red1;

    .svg {
      &-stencil {
        stroke: transparent;
      }

      &-solid {
        fill: $red1;
      }
    }
  }
}

@mixin like-button($width: null, $height: $width) {
  width: $width;
  height: $height;

  &__unit {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--active {
    .icon-heart-list {
      &--default,
      &--line {
        opacity: 0;
      }

      &--active {
        opacity: 1;
      }
    }
  }
}

// ANCHOR badge
@mixin badge(
  $height: 18px,
  $padding: 4px 5px 0 5px,
  $borderRadius: 18px,
  $background: $oldGray6,
  $fontSize: 11px,
  $fontWeight: normal,
  $color: $white,
  $verticalAlign: middle
) {
  @if $background == $blue1 or $background == #0078ff or $background == $blue700 {
    @include channelColor(wusinsa, background-color, $wusinsaAccent);
  }

  display: inline-block;
  min-width: 18px;
  height: $height;
  padding: $padding;
  border-radius: $borderRadius;
  box-sizing: border-box;
  background: $background;
  color: $color;
  font-family: $font-musinsa;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: normal;
  text-align: center;
  vertical-align: $verticalAlign;
}

// ANCHOR open
@mixin open(
  $display: inline-flex,
  $alignItem: center,
  $justifyContent: center,
  $height: 20px,
  $padding: null,
  $borderRadius: null,
  $borderRadiusValue: null,
  $background: $red1,
  $fontFamily: $font-musinsa,
  $fontSize: null,
  $fontWeight: bold,
  $color: $white,
  $verticalAlign: top,
  $direction: null
) {
  @if $fontSize == null {
    $fontSize: $height / 2;
  }

  @if $padding == null {
    $padding: 0 ceil($height * 0.3) 0;
  }

  @if $borderRadiusValue == null {
    $borderRadiusValue: $height / 2;
  }

  @if $direction == 'topLeft' {
    $borderRadius: $borderRadiusValue $borderRadiusValue 0 $borderRadiusValue;
  } @else if $direction == 'topRight' {
    $borderRadius: $borderRadiusValue $borderRadiusValue $borderRadiusValue 0;
  } @else if $direction == 'bottomLeft' {
    $borderRadius: $borderRadiusValue 0 $borderRadiusValue $borderRadiusValue;
  } @else {
    $borderRadius: 0 $borderRadiusValue $borderRadiusValue $borderRadiusValue;
  }

  display: $display;
  align-items: $alignItem;
  justify-content: $justifyContent;
  height: $height;
  padding: $padding;
  border-radius: $borderRadius;
  box-sizing: border-box;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: center;
  vertical-align: $verticalAlign;
}

// ANCHOR checkbox
@mixin checkbox(
  $isAlone: false,
  $display: inline-flex,
  $alignItems: null,
  $width: auto,
  $height: null,
  $size: 20px,
  $padding: null,
  $margin: null,
  $borderRadius: 100%,
  $color: $black
) {
  @if $display == 'inline-flex' or $display == 'flex' {
    $alignItems: center;
  }

  @if ($padding == null) {
    @if $isAlone {
      $padding: 0 0 0 $size;
    } @else {
      $padding: 0 0 0 $size + floor($size * 0.3);
    }
  }

  display: $display;
  align-items: center;
  margin: $margin;
  color: $color;

  > [class$='__label'] {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: $width;
    min-height: $size;
    padding: $padding;
    font-size: ceil($size * 0.7);
    color: inherit;
    text-align: left;
    white-space: normal;
    cursor: pointer;

    &::before,
    &::after {
      display: block;
      box-sizing: border-box;
    }

    &::before {
      @include position(absolute, 0, null, 0, 0);

      width: $size;
      height: $size;
      border: 1px solid $oldGray5;
      border-radius: $borderRadius;
      background-color: $oldGray3;
      content: '';
    }

    &::after {
      // left 값은 아래 공식을 따름
      // left 로 부터 label::before 의 width 만큼 떨어짐 -> $size / 2
      // 체크아이콘 width 의 반 만큼 left 값을 다시 빼줌 -> (($size / 4) - 1px) / 2
      @include position(absolute, ($size / 2) + 1, null, null, floor((($size / 2) - (($size / 4) - 1px) / 2)));

      width: ($size / 4) - 1px; // 20 기준 4px
      height: ($size / 2) - 2px; // 20 기준 8px;
      border-right: 1px solid $white;
      border-bottom: 1px solid $white;
      transform: translateY(calc(-50% - 2px)) rotate(45deg);
    }
  }

  > input[type='checkbox'] {
    // checked
    &:checked + [class$='__label'] {
      &::before {
        border-color: $blue700;
        background-color: $blue700;
      }

      &::after {
        content: '';
      }
    }

    // disabled
    &:disabled {
      &:not(:checked) + [class$='__label']::before {
        border-color: #eee;
        background-color: $oldGray2;
      }

      &:checked + [class$='__label']::before {
        border-color: $oldGray5;
        background-color: $oldGray5;
      }
    }
  }
}

// ANCHOR radio
@mixin radio(
  $isAlone: false,
  $display: inline-flex,
  $alignItems: null,
  $width: auto,
  $height: null,
  $size: 20px,
  $padding: null,
  $margin: null,
  $borderRadius: 100%,
  $color: $black
) {
  @if $display == 'inline-flex' or $display == 'flex' {
    $alignItems: center;
  }

  @if ($padding == null) {
    @if $isAlone {
      $padding: 0 0 0 $size;
    } @else {
      $padding: 0 0 0 $size + floor($size * 0.3);
    }
  }

  display: $display;
  align-items: center;
  margin: $margin;
  color: $color;

  > [class$='__label'] {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: $width;
    min-height: $size;
    padding: $padding;
    font-size: ceil($size * 0.7);
    color: inherit;
    text-align: left;
    white-space: normal;
    cursor: pointer;

    &::before,
    &::after {
      display: block;
      border-radius: $borderRadius;
      box-sizing: border-box;
    }

    &::before {
      @include position(absolute, 0, null, 0, 0);

      width: $size;
      height: $size;
      border: 1px solid $oldGray5;
      background-color: $oldGray3;
      content: '';
    }

    &::after {
      @include position(absolute, $size / 2, null, null, $size / 4);

      width: ($size / 2);
      height: ($size / 2);
      background-color: $white;
      transform: translateY(-50%);
    }
  }

  > input[type='radio'] {
    // checked
    &:checked + [class$='__label'] {
      &::before {
        border-color: $blue700;
        background-color: $blue700;
      }

      &::after {
        content: '';
      }
    }

    // disabled
    &:disabled {
      &:not(:checked) + [class$='__label']::before {
        border-color: #eee;
        background-color: $oldGray2;
      }

      &:checked + [class$='__label']::before {
        border-color: $oldGray5;
        background-color: $oldGray5;
      }
    }

    &:focus + label {
      outline: 1px dotted $black;
    }
  }
}

// ANCHOR input text
@mixin inputText(
  $position: relative,
  $display: inline-flex,
  $width: 100%,
  $height: 50px,
  $borderColor: $oldGray5,
  $boxSizing: border-box,
  $background: $white,
  $fontSize: 15px
) {
  position: $position;
  display: $display;
  width: $width;
  height: $height;
  background: transparent;
  font-size: $fontSize;

  [class*='__text'] {
    @include placeholder($oldGray6);

    appearance: none;
    width: 100%;
    padding: 0 10px;
    border: 1px solid $borderColor;
    box-sizing: $boxSizing;
    font-size: $fontSize;
    background: $background;

    &:read-only {
      background: $oldGray4;
      color: $black;
      opacity: 1;
    }

    &:disabled {
      @include placeholder($oldGray5);

      border-color: $oldGray4;
      background: $white;
    }

    &[class$='--error'] {
      border-color: $red1;

      + [class$='__description'] {
        color: $red1;
      }
    }

    &[class$='--positive'] + [class$='__description'] {
      color: $blue1;
    }
  }

  & [class$='__description'] {
    @include position(absolute, null, null, 0, 0);

    font-size: 12px;
    color: $oldGray5;
    transform: translateY(calc(100% + 7px));
  }
}

// ANCHOR bullet
// bullet 의 top 값
@function bulletTop($size) {
  $top: #{floor($size * 1.5 / 2) - 1};
  @return $top;
}

@mixin bullet($fontSize: 14px, $color: $black, $liMarginTop: 5px) {
  display: block;
  font-size: $fontSize;
  padding-left: 10px;

  > li {
    position: relative;
    display: block;
    word-break: break-all;
    text-align: left;

    & + li {
      margin-top: $liMarginTop;
    }

    &[class$='normal'] {
      color: $oldGray7;

      &::before {
        background-color: $oldGray7;
      }
    }

    &[class$='warning'] {
      color: $orange1;

      &::before {
        background-color: $orange1;
      }
    }

    &[class$='positive'] {
      color: $blue1;

      &::before {
        background-color: $blue1;
      }
    }

    &[class$='danger'] {
      color: $red1;

      &::before {
        background-color: $red1;
      }
    }

    &[class$='accent'] {
      color: $black;

      &::before {
        background-color: $black;
      }
    }

    &::before {
      $top: bulletTop($fontSize);
      @include position(absolute, $top, null, null, -8px);

      display: block;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      content: '';
    }
  }
}

// ANCHOR tooltip
@mixin tooltip(
  $direction: null,
  $flexWrap: null,
  $width: auto,
  $height: auto,
  $position: absolute,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $padding: 8px 8px 6px,
  $borderRadius: 4px,
  $background: $blue700,
  $fontFamily: $font-default,
  $fontSize: 11px,
  $fontWeight: normal,
  $color: $white,
  $lineHeight: 1.5,
  $textAlign: left,
  $translate: null,
  $translateX: 8px,
  $translateY: 8px,
  // arro
    $arrowTop: null,
  $arrowRight: null,
  $arrowBottom: null,
  $arrowLeft: null
) {
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: $flexWrap;
  width: $width;
  height: $height;
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  padding: $padding;
  border-radius: $borderRadius;
  box-sizing: border-box;
  background: $background;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  text-align: $textAlign;
  line-height: $lineHeight;
  animation: tooltipOutSlide 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards, fadeOut 0.3s ease forwards;
  opacity: 0;

  @content;

  @if $background == $blue700 {
    @include channelColor(wusinsa, background, $wusinsaAccent);
  }

  // tooltip 기본 위치
  @if $direction == 'top' {
    transform: translate3d(0, #{$translateY}, 0);

    &::before {
      @if $arrowBottom == null {
        $arrowBottom: -3px;
      }

      @include position(absolute, $arrowTop, $arrowRight, $arrowBottom, $arrowLeft);
    }
  } @else if $direction == 'right' {
    transform: translate3d(-#{$translateX}, 0, 0);

    &::before {
      @if $arrowLeft == null {
        $arrowLeft: -3px;
      }

      @include position(absolute, $arrowTop, $arrowRight, $arrowBottom, $arrowLeft);
    }
  } @else if $direction == 'bottom' {
    transform: translate3d(0, -#{$translateY}, 0);

    &::before {
      @if $arrowTop == null {
        $arrowTop: -3px;
      }

      @include position(absolute, $arrowTop, $arrowRight, $arrowBottom, $arrowLeft);
    }
  } @else {
    transform: translate3d(#{$translateX}, 0, 0);

    &::before {
      @if $arrowRight == null {
        $arrowRight: -3px;
      }

      @include position(absolute, $arrowTop, $arrowRight, $arrowBottom, $arrowLeft);
    }
  }

  &::before {
    width: 6px;
    height: 6px;
    background: $blue700;
    transform: rotate(45deg) translateZ(0);
    content: '';
  }

  [class$='__close'] {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 30px;
    height: 30px;
    margin: -8px -8px -8px 0;

    > svg polygon {
      fill: $white;
    }
  }

  &--active {
    display: inline-flex;
    animation: tooltipInSlide 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards, fadeIn 0.3s ease forwards;
  }

  &--hide {
    animation: tooltipOutSlide 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards, fadeOut 0.3s ease forwards;
  }

  // tooltip active 위치
  @keyframes tooltipInSlide {
    0% {
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes tooltipOutSlide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    99.9% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      display: none;
    }
  }
}
