@charset "UTF-8";

$SPRITE_RATIO: sprite-ratio();

%sp-filter {
    display: inline-block;
    overflow: hidden;
}

@function get-sp-filter-map($filename) {
    @if map-has-key($sp-filter, $filename) {
        @return map-get($sp-filter, $filename);
    }

    $error_message: "sp-filter에서 이미지 파일 #{$filename}을 찾을 수 없습니다.";
    @error $error_message;
}

@function sp-filter-width($filename) {
    $dimension-map: get-sp-filter-map($filename);
    $width: map-get($dimension-map, width);

    @if ($width % $SPRITE_RATIO != 0) {
        $error_message: "sp-filter/#{$filename}의 width가 #{$width}로 이미지 background-size 비율(#{$SPRITE_RATIO})과 맞지 않습니다. 크기를 확인해주세요.";
        @error $error_message;
    }

    @return $width/$SPRITE_RATIO;
}

@function sp-filter-height($filename) {
    $dimension-map: get-sp-filter-map($filename);
    $height: map-get($dimension-map, height);

    @if ($height % $SPRITE_RATIO != 0) {
        $error_message: "sp-filter/#{$filename}의 height가 #{$height}로 이미지 background-size 비율(#{$SPRITE_RATIO})과 맞지 않습니다. 크기를 확인해주세요.";
        @error $error_message;
    }

    @return $height/$SPRITE_RATIO;
}

@function sp-filter-offset-x($filename) {
    $dimension-map: get-sp-filter-map($filename);
    @return map-get($dimension-map, offset_x)/$SPRITE_RATIO;
}

@function sp-filter-offset-y($filename) {
    $dimension-map: get-sp-filter-map($filename);
    @return map-get($dimension-map, offset_y)/$SPRITE_RATIO;
}

@mixin sp-filter-width($filename) {
    width: sp-filter-width($filename);
}

@mixin sp-filter-height($filename) {
    height: sp-filter-height($filename);
}

@mixin sp-filter-bg($filename) {
    $offset-x : sp-filter-offset-x($filename);
    $offset-y : sp-filter-offset-y($filename);

    background: url('../im/filter/sp-filter.png?v=#{timestamp()}') no-repeat;
    background-position: ($offset-x) ($offset-y);
    @if ($SPRITE_RATIO != 1) {
        background-size: (316px/$SPRITE_RATIO) (316px/$SPRITE_RATIO);
    }
}

@mixin sp-filter($filename, $padding-top: 0, $padding-right: 0, $padding-bottom: 0, $padding-left: 0, $display: inline-block){
    $width : sp-filter-width($filename);
    $height : sp-filter-height($filename);
    $offset-x : sp-filter-offset-x($filename);
    $offset-y : sp-filter-offset-y($filename);

    @extend %sp-filter;
    width: ($width + $padding-left + $padding-right);
    height: ($height + $padding-top + $padding-bottom);
    background: url('../im/filter/sp-filter.png?v=#{timestamp()}') no-repeat;
    background-position: ($offset-x + $padding-left) ($offset-y + $padding-top);
    @if ($SPRITE_RATIO != 1) {
        background-size: (316px/$SPRITE_RATIO) (316px/$SPRITE_RATIO);
    }
}

$sp-filter: (
    bg_filter_color_animal: (offset_x: -64px, offset_y: 0px, width: 60px, height: 60px),
    bg_filter_color_blackdenim: (offset_x: 0px, offset_y: -192px, width: 60px, height: 60px),
    bg_filter_color_camo: (offset_x: 0px, offset_y: -64px, width: 60px, height: 60px),
    bg_filter_color_check: (offset_x: -64px, offset_y: -64px, width: 60px, height: 60px),
    bg_filter_color_darkbluedenim: (offset_x: -128px, offset_y: 0px, width: 60px, height: 60px),
    bg_filter_color_denim: (offset_x: -128px, offset_y: -64px, width: 60px, height: 60px),
    bg_filter_color_dot: (offset_x: 0px, offset_y: -128px, width: 60px, height: 60px),
    bg_filter_color_etc: (offset_x: -64px, offset_y: -128px, width: 60px, height: 60px),
    bg_filter_color_flower: (offset_x: -128px, offset_y: -128px, width: 60px, height: 60px),
    bg_filter_color_ginghamcheck: (offset_x: -192px, offset_y: 0px, width: 60px, height: 60px),
    bg_filter_color_glencheck: (offset_x: -192px, offset_y: -64px, width: 60px, height: 60px),
    bg_filter_color_gold: (offset_x: -192px, offset_y: -128px, width: 60px, height: 60px),
    bg_filter_color_harringbone: (offset_x: 0px, offset_y: 0px, width: 60px, height: 60px),
    bg_filter_color_houndtoothcheck: (offset_x: -64px, offset_y: -192px, width: 60px, height: 60px),
    bg_filter_color_lightbluedenim: (offset_x: -128px, offset_y: -192px, width: 60px, height: 60px),
    bg_filter_color_mediumbluedenim: (offset_x: -192px, offset_y: -192px, width: 60px, height: 60px),
    bg_filter_color_ombrecheck: (offset_x: -256px, offset_y: 0px, width: 60px, height: 60px),
    bg_filter_color_paisley: (offset_x: -256px, offset_y: -64px, width: 60px, height: 60px),
    bg_filter_color_patchwork: (offset_x: -256px, offset_y: -128px, width: 60px, height: 60px),
    bg_filter_color_pattern: (offset_x: -256px, offset_y: -192px, width: 60px, height: 60px),
    bg_filter_color_silver: (offset_x: 0px, offset_y: -256px, width: 60px, height: 60px),
    bg_filter_color_stripe: (offset_x: -64px, offset_y: -256px, width: 60px, height: 60px),
    bg_filter_color_tartancheck: (offset_x: -128px, offset_y: -256px, width: 60px, height: 60px),
    bg_filter_color_tropical: (offset_x: -192px, offset_y: -256px, width: 60px, height: 60px),
    bg_filter_color_windowpanecheck: (offset_x: -256px, offset_y: -256px, width: 60px, height: 60px),
);

@each $filename, $dimension-map in $sp-filter {
    $width: map-get($dimension-map, width);
    $height: map-get($dimension-map, height);

    @if ($width % $SPRITE_RATIO != 0) {
        $error_message: "sp-filter/#{$filename}의 width가 #{$width}로 이미지 background-size 비율(#{$SPRITE_RATIO})과 맞지 않습니다. 크기를 확인해주세요.";
        @error $error_message;
    }

    @if ($height % $SPRITE_RATIO != 0) {
        $error_message: "sp-filter/#{$filename}의 height가 #{$height}로 이미지 background-size 비율(#{$SPRITE_RATIO})과 맞지 않습니다. 크기를 확인해주세요.";
        @error $error_message;
    }
}
