@charset 'utf-8';

.cm-filter__sort-v2 {
  $this: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin-left: 10px;
  border: 1px solid $oldGray4;
  border-radius: 100%;
  background-color: transparent;
  font-size: 14px;
  line-height: normal;
  color: $oldGray6;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;

  &__icon {
    vertical-align: middle;
    transform: translateY(-1px) scaleX(-1);

    .svg-color {
      fill: $oldGray7;
    }
  }

  &__text {
    display: block;
    font-family: $font-default;
    line-height: 17px;
  }

  // 전체 인기 단독 좋아요 버튼
  &--plain {
    width: auto;
    min-width: 35px;
    height: auto;
    padding: 3px 5px;
    margin-left: 0;
    border: 0;
    border-radius: 0;
    color: $oldGray7;

    @at-root {
      // NOTE ABC순, 가나다순 버튼은
      // 항상 cm-filter__tabs-v2__static
      // 마지막 2번째 자식이기 때문에 간격을 할당함
      .cm-filter__tabs-v2__static &:nth-last-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &--transfer {
    position: relative;
    width: auto;
    margin: 0 4px 0 15px;
    border: 0;
    border-radius: 0;
    color: $oldGray7;

    &::after {
      @include position(absolute, 4px, null, null, -15px);

      width: 1px;
      height: 14px;
      background-color: #e5e5e5;
      content: '';
    }

    #{$this}__text {
      margin-left: 3px;
    }
  }

  &--active {
    &:not(.cm-filter__sort-v2--plain):not(.cm-filter__sort-v2--transfer) {
      border-color: $musinsaAccent;
      background-color: $musinsaAccent;
      color: $white;
    }

    &.cm-filter__sort-v2--plain {
      font-weight: bold;
      color: $musinsaAccent;
    }
  }
}
