@charset 'utf-8';

// TODO brands, category, brand-index filter 변경 후 삭제
.cm-filter__category {
  $this: &;
  @include overflowScroll('x');

  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0;
  scroll-behavior: smooth;

  &--text {
    border-bottom: 1px solid $gray200;
  }

  &__button {
    display: inline-block;
    overflow: hidden;
    position: relative;
    color: $black;
    text-align: center;
    vertical-align: top;

    &--active {
      color: $musinsaAccent;

      #{$this}__content::before {
        @include position(absolute, 0, 50%);

        display: block;
        width: 65px;
        height: 65px;
        border: 1px solid $musinsaAccent;
        border-radius: 100%;
        box-sizing: border-box;
        transform: translateX(50%);
        content: '';
      }
    }
  }

  &__content {
    width: 85px;
  }

  &__image {
    overflow: hidden;
    position: relative;
    width: 65px;
    height: 65px;
    margin: 0 auto;
    border-radius: 100%;
    background-color: $oldGray3;
    mix-blend-mode: multiply;

    > img {
      @include position(absolute, 50%, 50%);

      display: block;
      width: 100%;
      transform: translate(50%, -50%);
      mix-blend-mode: multiply;
    }
  }

  &__desc {
    @include ellipsis(1);

    margin-top: 6px;
    font-size: 14px;
    line-height: normal;
  }
}
