.slider-multi {
  $this: &;

  user-select: none;
  position: relative;
  margin: 0 12px;

  &__contents {
    width: 100%;
    border-radius: 2px;
    background-color: $oldGray3;
  }

  &__input {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 2px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;

    @mixin sliderThumb {
      appearance: none;
      width: 14px;
      height: 26px;
      border: 0 none;
      border-radius: 0;
      opacity: 0;
      background-color: transparent;
      pointer-events: all;
    }

    &::-webkit-slider-thumb {
      @include sliderThumb;
    }

    &::-moz-range-thumb {
      @include sliderThumb;
    }

    &--active {
      z-index: 3;
    }
  }

  &__units {
    position: relative;
    height: 2px;
    margin: 0 5px;
    z-index: 1;
  }

  &__bar {
    @include position(absolute, 0, 0, 0, 0);

    border-radius: 2px;
    background-color: $oldGray3;
    z-index: 1;
  }

  &__range {
    @include channelColor(wusinsa, background-color, $wusinsaAccent);
    @include position(absolute, 0, 0, 0, 0);

    border-radius: 2px;
    background-color: $musinsaAccent;
    z-index: 2;
  }

  &__handle {
    @include channelColor(wusinsa, background-color, $wusinsaAccent);

    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $musinsaAccent;
    transition: top, left, right, bottom 0.3s ease-in-out;
    will-change: auto;
    z-index: 3;

    &::before {
      @include position(absolute, 0, 0, 0, 0);

      border-radius: 100%;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      content: "";
    }

    &::after {
      @include position(absolute, 50%, null, 0, 50%);

      width: 14px;
      height: 26px;
      background: transparent;
      transform: translate(-50%, -50%);
      opacity: 0.2;
      content: "";
    }

    &--left {
      transform: translate(-10px, calc(-50% + 1px));
    }

    &--right {
      transform: translate(10px, calc(-50% + 1px));
    }

    &--active::before {
      @include channelColor(wusinsa, background-color, $wusinsaAccent);

      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      background-color: $musinsaAccent;
      transition: all 0.3s ease-in-out;
    }
  }

  &__number {
    @include channelColor(wusinsa, color, $wusinsaAccent);
    @include position(absolute, null, -10px, 24px);

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
    font-size: 12px;
    color: $musinsaAccent;
    text-align: right;
    white-space: nowrap;
  }
}
