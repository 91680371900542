$yellow1: #ff923a;
$orange1: #ff5300;
$blue1: #14aaff;
$red1: #ff0000;
$red2: #eb0000;
$musinsaAccent: #0078ff;

// Gray Scale
$oldGray1: #f9f9f9;
$oldGray2: #f5f5f5;
$oldGray3: #f1f1f1;
$oldGray4: #eeeeee;
$oldGray5: #cccccc;
$oldGray6: #aaaaaa;
$oldGray7: #777777;
$oldGray8: #595959;
$oldGray9: #333333;

// wuwinsa
$wusinsa1: #f8f8fa;
$wusinsa2: #f2f3f8;
$wusinsa3: #e3e5ed;
$wusinsa4: #c7cad4;
$wusinsa5: #a5a9b9;
$wusinsa6: #7e8291;
$wusinsa7: #5d6070;
$wusinsa8: #3f424f;
$wusinsa9: #292b33;

// mensinsa
$mensinsa1: #f7f8fa;
$mensinsa2: #eef1f7;
$mensinsa3: #d7deed;
$mensinsa4: #a5b6d4;
$mensinsa5: #6c82b9;
$mensinsa6: #405791;
$mensinsa7: #253a70;
$mensinsa8: #1a294f;
$mensinsa9: #111a33;

$font-default: '-apple-system', 'Apple SD Gothic Neo', 'Roboto', 'Noto Sans KR', 'helvetica', 'Gulim', sans-serif;
$font-musinsa: 'Musinsa', $font-default;
$font-musinsa-stencil: 'Musinsa Stencil', $font-default;
$font-musinsa-italic: 'Musinsa Italic', $font-default;
$font-musinsa-round: 'Musinsa Round', $font-default;
// TODO Pretendard font 적용 완료 시, 아래 내용 삭제 할 것!
$font-pretendard: 'Pretendard', $font-default;
// TODO Pretendard font 적용 완료 시, 삭제 할 것! -- 여기까지

/**********
  ANCHOR
  Desing System 2.0
**********/

// FOUNDATION - Color Libraries
$black: #000000;
$white: #ffffff;

$gray900: #333333;
$gray800: #4f4f4f;
$gray700: #6e6e6e;
$gray600: #919191;
$gray500: #b3b3b3;
$gray400: #d1d1d1;
$gray300: #e5e5e5;
$gray200: #f3f3f3;
$gray100: #f9f9f9;

$blue900: #2242cd;
$blue800: #1564ec;
$blue700: #0078ff;
$blue600: #008bff;
$blue500: #009aff;
$blue400: #14aaff;
$blue300: #53baff;
$blue200: #8bceff;
$blue100: #bae0ff;
$blue50: #e2f3ff;

$caution: #ff923a;
$cautionText: #ff5300;
$error: #ff0000;

$wusinsaGray900: #292b33;
$wusinsaGray800: #3f424f;
$wusinsaGray700: #5d6070;
$wusinsaGray600: #7e8291;
$wusinsaGray500: #a5a9b9;
$wusinsaGray400: #c7cad4;
$wusinsaGray300: #e5e5ed;
$wusinsaGray200: #f2f3f8;
$wusinsaGray100: #f8f8fa;

$wusinsaAccent: #0078ff;

$mensinsaPrimary: #11214a;

$outletPrimary: #e63500;
$outletAccent: #0089d2;

$terracePrimary: #453675;
$terraceAccent: #8383ff;
$terraceSecondaryCream: #e3e1d9;
$terraceSecondaryCharcoal: #262626;

/* MDS 2.0 color - 24.04.17*/
$v2Gray100: #f5f5f5;
$v2Gray200: #ebebeb;
$v2Gray300: #e0e0e0;
$v2Gray400: #cccccc;
$v2Gray500: #8a8a8a;
$v2Gray600: #666666;
$v2Gray700: #2a2a2a;

$v2Red: #f31110;
$v2Blue: #245eff;
$v2Yellow: #fa9200;
$v2LabelBlue: #3a6eff;
