@charset 'utf-8';

.cm-filter__navigation {
  padding: 8px 0 10px;
  margin: 0 15px 5px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 0;

  &__text {
    font-size: 14px;
    color: $oldGray7;

    & + & {
      position: relative;
      padding-left: 15px;

      &::before {
        @include position(absolute, 50%, null, null, 3px);

        width: 5px;
        height: 5px;
        border-bottom: 1px solid $oldGray7;
        border-left: 1px solid $oldGray7;
        transform: translateY(-50%) rotate(225deg) skew(-10deg,-10deg);
        content: '';
      }
    }

    &:last-child {
      color: $black;

      &::before {
        border-color: $black;
      }
    }
  }
}