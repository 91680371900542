// TODO mixin 으로 스타일 대체
// 하지만 기존 cm-badge 를 쓰고 있는 페이지가 있기 때문에
// scss 파일은 삭제 하지 않고 보존.
.cm-badge {
  display: inline-block;
  min-width: 8px;
  height: 14px;
  padding: 4px 5px 0 5px;
  border-radius: 18px;
  color: #ffffff;
  font-family: $font-musinsa;
  font-size: 11px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  background-color: $oldGray6;

  &--new {
    background-color: $musinsaAccent;
  }

  @at-root {
    .header__button & {
      @include position(absolute, 20px, 2px);
    }
  }
}
