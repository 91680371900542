.cm-util {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 50px;
  z-index: 100;

  // lotation
  &__left,
  &__center,
  &__right {
    display: flex;
    align-items: center;
  }

  &__center {
    margin: auto;
  }

  &__right {
    margin: 0 5px 0 auto;
  }

  // element
  &__title {
    padding: 0 15px;
    font-size: 16px;
    font-weight: bold;
  }

  &__count {
    display: flex;
    padding: 0 15px;

    > strong {
      margin-left: 4px;
    }

    > span {
      margin: 3px 0 0 4px;
      font-size: 12px;
      color: $oldGray6;
      line-height: 18px;
    }
  }

  &__tab {
    height: 31px;
    padding: 5px;
    line-height: 1;
    color: $oldGray6;

    &:first-child {
      margin-left: 10px;
    }

    &.active {
      font-weight: bold;
      color: $black;
    }

    @media (max-width: 320px) {
      padding: 4px;
    }
  }

  &__campaign {
    display: flex;
    padding: 10px;

    > strong {
      margin: 0 4px 0 2px;
    }

    > svg {
      vertical-align: top;
    }

    &.active {
      color: $musinsaAccent;

      .svg-color {
        fill: $musinsaAccent;
      }
    }
  }
}