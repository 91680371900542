@mixin utilIconPosition {
  position: relative;
  top: 9px;
  width: 30px;
  height: 30px;
  padding: 4px;
  box-sizing: border-box;
}

.header {
  $this: &;

  height: 50px;
  line-height: 50px;
  background: $white;
  z-index: 500;

  &__badge {
    @include badge($background: $musinsaAccent);
    @include position(absolute, 20px, 2px);
  }

  &__area {
    @include position(fixed, 0, 0, null, 0);

    display: flex;
    justify-content: center;
    z-index: 500;
    height: 50px;
    line-height: 50px;
    background-color: $white;

    @at-root {
      // header fixed 상쇄
      #{$this}--static & {
        position: relative;
      }

      // NOTE cm-popup-full에서 헤더모듈 사용
      #{$this}--popup & {
        #{$this}__title {
          font-size: 18px;
        }
      }

      // user-snap 의 댓글팝업에서 사용
      .layer-comment & {
        position: relative;
      }
    }
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    // TODO design system 2.0에서 전역으로 적용 후 제거 예정
    font-family: 'Apple SD Gothic Neo', sans-serif, HelveticaNeue, Roboto, 'Noto Sans KR', helvetica, Gulim, sans-serif;
    font-size: 16px;
    color: $black;

    &__link {
      display: flex;
      align-items: center;
      height: 100%;

      > img {
        width: auto;
        height: 24px;
        vertical-align: middle;
      }
    }
  }

  &__util {
    &--category {
      @include utilIconPosition;

      &.active {
        transform: rotate(180deg);
      }
    }

    &--tooltip {
      @include utilIconPosition;

      > svg .svg-color {
        fill: $black;
      }

      &::after {
        @include position(absolute, 100%, null, null, 50%);
        @include channelColor(wusinsa, border-bottom-color, $wusinsaAccent);

        display: none;
        width: 0;
        height: 0;
        border-right: 4px solid transparent;
        border-bottom: 4px solid $blue700;
        border-left: 4px solid transparent;
        transform: translateX(-4px);
        content: '';
      }

      &.active {
        & + #{$this}__tooltip {
          display: block;
        }

        &::after {
          display: block;
        }
      }
    }
  }

  // 추후 product design guide 의 mixin으로 대체 해야함
  &__tooltip {
    @include position(fixed, 43px, 50%);
    @include channelColor(wusinsa, background-color, $wusinsaAccent);

    display: none;
    width: 220px;
    padding: 9px 10px 7px 10px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: $musinsaAccent;
    font-family: $font-default;
    font-size: 11px;
    line-height: 17px;
    color: #ffffff;
    text-align: left;
    transform: translateX(50%);
    z-index: 10;

    // main like tooltip
    @at-root {
      #{$this}__button--main-like-tooltip & {
        @include tooltip(
          $direction: bottom,
          $width: max-content,
          $top: calc(100% + 1px),
          $right: -20px,
          $arrowRight: 37px
        );
      }
    }
  }
  // TODO product design guide 의 mixin으로 대체 해야함

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    @include position(absolute, 0, null, 0, 5px);

    justify-content: flex-start;
  }

  &__right {
    @include position(absolute, 0, 5px, 0);

    justify-content: flex-end;
  }

  &__button {
    position: relative;
    padding: 5px;
    transform: translateZ(0);

    svg {
      vertical-align: middle;

      .svg-color {
        fill: black;
      }
    }

    &--text {
      font-family: $font-default;
      font-size: 16px;
      color: $musinsaAccent;
    }

    &__text {
      margin-right: 5px;
      font-size: 14px;
      color: $gray700;
    }
  }

  // like active
  &__button--like {
    width: 40px;
    height: 40px;
    box-sizing: border-box;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 30px;
      height: 30px;

      &:nth-of-type(1) {
        transform: scale(0);
        z-index: 10;

        &.on {
          animation: heartOnAbled 900ms ease-out forwards;
        }

        &.off {
          animation: heartOnDisabled 550ms ease-out forwards;
        }
      }

      &:nth-of-type(2) {
        opacity: 1;
        transform: scale(1);
        transition: all 300ms 70ms cubic-bezier(0.34, 1.56, 0.64, 1);
        z-index: 20;

        &.on {
          transform: scale(1);
        }

        &.off {
          opacity: 0;
          transform: scale(0.5);
        }
      }
    }

    @keyframes heartOnAbled {
      0% {
        transform: scale(0);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      22% {
        transform: scale(1.28);
      }
      44% {
        transform: scale(0.95);
      }
      65% {
        transform: scale(1.01);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes heartOnDisabled {
      0% {
        transform: scale(1);
        animation-timing-function: cubic-bezier(0.6, 0, 1, 1);
      }
      18% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
  }

  // ANCHOR header--tab modifier
  &--tab {
    height: 95px;
  }

  // ANCHOR header-tab module
  &-tab {
    @include position(fixed, 50px, 0, null, 0);

    display: flex;
    height: 45px;
    background-color: $white;
    z-index: 500;

    @at-root {
      // NOTE 좋아요 페이지에서 style.mobile.css 에서 header-tab 의 height 를 117px 로 설정하고 있어서
      // 선택자 높혀서 상쇄하기 위한 목적으로 사용
      .wrapper-wish-goods & {
        height: 45px;
      }
    }

    &__link {
      flex: 1 1;
      position: relative;
      padding: 11px 10px 10px 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: $gray500;
      text-align: center;

      &::before {
        @include position(absolute, null, 0, 0, 0);

        display: block;
        height: 1px;
        background-color: $gray400;
        content: '';
      }

      &--active {
        color: $black;

        &::before {
          height: 2px;
          background-color: $black;
        }

        #{$this}-tab__link__count {
          color: $blue700;
        }
      }
    }

    .tab-tooltip--right {
      @include tooltip(
        $direction: bottom,
        $width: 158px,
        $top: calc(100% + 8px),
        $left: calc(50% - 79px),
        $arrowLeft: calc(50% - 3px)
      );
    }
  }
}

// alarm new
.alarm-new {
  $this: &;

  @at-root .musinsa-header .util.left#{$this} {
    left: 0;
    top: 0;
  }

  &__button {
    width: 50px;
    line-height: 0;
    transform: translateZ(0);
  }

  &__tooltip {
    @include tooltip($direction: bottom, $width: auto, $top: calc(100% + 2px), $right: -85px, $arrowLeft: 10px);
  }
}
