// NOTE 추후 mixin 으로 변경 해야함

.cm-toast {
  @include position(fixed, null, 15px, null, 15px);
  bottom: calc(env(safe-area-inset-bottom) + 80px);
  bottom: calc(constant(safe-area-inset-bottom) + 80px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 345px;
  // width: 345px;
  padding: 3px 15px 3px;
  margin: 0 auto;
  border-radius: 4px;
  box-sizing: border-box;
  background: #383838;

  &__text {
    margin: 11px 0 11px;
    color: $white;
    word-break: break-all;
  }

  &__button {
    align-self: flex-end;
    margin: auto 0 11px auto;
    color: $musinsaAccent;
  }
}
