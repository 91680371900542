.MCommonTabUnderline {
  $this: &;

  @include position(sticky, 50px, null, null, 0);

  display: flex;
  width: 100%;
  height: 45px;
  background-color: $white;
  z-index: 500;

  &__button {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
    color: $gray500;
    text-align: center;

    &::before {
      @include position(absolute, null, 0, 0, 0);

      display: block;
      height: 1px;
      background-color: $gray400;
      content: '';
    }

    &--active {
      color: $black;

      &::before {
        height: 2px;
        background-color: $black;
      }
    }
  }

  &__count {
    display: inline-flex;
    padding: 3px 0 0 4px;
    font-family: $font-musinsa;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: $gray500;

    @at-root #{$this}__button--active & {
      @include channelColor(wusinsa, color, $wusinsaAccent);

      color: $blue700;
    }
  }

  @at-root {
    .popup-pickup-guide & {
      top: 0;
    }
  }
}
