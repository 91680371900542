@charset 'UTF-8';

.cm-select {
  $this: &;

  position: relative;
  height: 100%;
  border: 1px solid $oldGray5;
  box-sizing: border-box;

  &::after {
    @include position(absolute, 50%, 0);
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid $black;
    border-right: 1px solid $black;
    transform: rotate(45deg) translate(-20px, 50%);
    content: "";
  }

  &__box {
    appearance: none;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    padding: 0 44px 0 10px;
    border: 0;
    background-color: $white;
    font-family: $font-default;
    font-size: 1em;
    white-space: nowrap;
  }

  &:not(.cm-select--error):focus {
    border-color: $oldGray6;
  }

  // disabled
  &--disabled {
    border-color: $oldGray4;

    #{$this}__box {
      color: $oldGray5;
    }

    &::after {
      border-color: $oldGray4;
    }
  }

  // error
  &--error {
    border-color: $red1;
  }

  @at-root {
    // filter 에서 사용
    .cm-filter__period & {
      display: inline-block;
      width: 26.08%;
      height: 40px;
      margin-right: 5px;
      font-size: 14px;
      vertical-align: top;

      &:last-of-type() {
        margin-right: 0;
      }
    }

    // 통합검색에서 사용
    .n-list-filter .cm-filter__period & {
      width: 100px;
    }

    // select box 기본 색상.
    .cm-select--default {
      color: $oldGray6;
    }
  }
}
