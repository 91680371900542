@charset 'utf-8';

.cm-popup-full {
  @include position(fixed, 0, 0, 0, 0);

  background-color: $white;
  transform: translateY(calc(100% + 100px));
  z-index: 9999;

  &.active {
    transition: transform .3s ease-in-out;
    transform: translateY(0);
  }

  &__contents {
    @include safearea(padding-bottom, 0);

    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 50px);

    @at-root {
      .popup-pickup-guide & {
        height: 100%;
      }
    }
  }
}