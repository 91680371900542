.popup-dim {
  @include position(fixed, 0, 0, 0, 0);

  display: none;
  max-width: var(--max-width);
  margin: 0 auto;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.4);

  &.active {
    display: block;
    animation: fadeIn 0.15s;
  }
}
