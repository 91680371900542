@charset 'utf-8';

// TODO brands, category, brand-index filter 변경 후 삭제
.cm-filter__category {
  $this: &;
  @include overflowScroll('x');

  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 0;
  scroll-behavior: smooth;

  &--tab {
    border-bottom: 1px solid $gray100;
  }

  &__tab {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 10px 0 11px;
    font-size: 14px;
    font-weight: 400;
    color: $gray500;

    &--active {
      font-weight: 700;
      color: $black;

      &::after {
        @include position(absolute, null, null, 0, 0);

        display: block;
        width: 100%;
        height: 2px;
        background-color: $black;
        content: '';
      }
    }

    & + #{$this}__tab {
      margin-left: 24px;
    }
  }

  &__text {
    width: auto;
    font-size: 14px;
  }
}
