.MCommonCoupon {
  $this: &;

  @include position(relative);

  display: flex;
  flex-direction: column;
  height: 140px;
  padding: 20px 15px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $gray200;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

  @media (max-width: 320px) {
    height: 162px;
  }

  @at-root {
    #{$this}List {
      padding: 20px 15px 12px;
      background-color: $gray100;
      padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
      padding-bottom: calc(constant(safe-area-inset-bottom) + 12px);
      & + & {
        padding-bottom: 40px;
      }
      &:last-of-type {
        padding-bottom: 40px;
      }
      &__header {
        margin-bottom: 8px;

        &__title {
          font-size: 15px;
          line-height: 22.5px;
          font-weight: 700;
        }

        &__sub-title {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: #6e6e6e;
        }
      }
      &__noresult {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 270px 0;
        font-size: 14px;
        color: $gray600;
      }

      // 상품상세 팝업 쿠폰 영역 (쿠폰 받기)
      #coupon_down_layer & {
        margin: 20px -15px 0;
      }

      // 상품상세 팝업 쿠폰 영역 (내 보유쿠폰 보기)
      #popCoupon & {
        margin: 14px -15px 0;
      }

      // 장바구니 쿠폰 적용 팝업
      .cm-popup-full__contents:not(#coupon_down_list) & {
        min-height: 100%;
        box-sizing: border-box;
      }

      //장바구니 쿠폰 다운로드 팝업
      .cm-popup-full__contents#coupon_down_list & {
        background-color: #fff;
      }
    }
  }

  &--brand {
    border-color: $musinsaAccent;
  }

  &__title {
    overflow: hidden;
    display: -webkit-box;
    width: calc(100% - 70px);
    margin-bottom: 9px;
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    word-break: break-all;
    max-height: 42px;
    -webkit-line-clamp: unset;
    -webkit-box-orient: vertical;
  }

  &__information {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
  }

  &__label {
    display: inline-block;
    height: 20px;
    margin-right: 2px;
    padding: 0 6px;
    color: $musinsaAccent;
    line-height: 20px;
    font-size: 11px;
    font-weight: normal;
    font-family: $font-musinsa;
    background-color: #f3faff;
    border-radius: 2px;
    vertical-align: bottom;

    &--app {
      color: $gray800;
      background-color: $gray100;
    }
    &--direct,
    &--cart {
      color: $blue400;
    }
  }

  &__bottom {
    display: flex;
  }

  &__period {
    display: flex;
    font-size: 12px;
    font-family: $font-musinsa;

    em {
      margin-right: 5px;
      color: $orange1;
    }

    span {
      position: relative;
      padding-right: 6px;
      margin-right: 6px;
      color: $gray700;

      &::after {
        position: absolute;
        right: 0;
        top: 3px;
        display: block;
        width: 1px;
        height: 11px;
        background-color: $gray300;
        content: '';
      }

      &:last-of-type {
        padding-right: 0;
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &__discount {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    &__rate {
      margin-right: 6px;
      color: $musinsaAccent;
      font-weight: 400;
      font-size: 28px;
      font-family: $font-musinsa;
      line-height: 100%;
    }

    &__information {
      display: flex;
      align-items: flex-end;
      padding-top: 2px;
      color: $gray500;
      font-size: 12px;
      font-family: $font-musinsa;

      em {
        margin-right: 4px;
        color: $musinsaAccent;
      }
    }
  }

  &__button {
    @include position(relative);

    height: 22px;
    margin-left: 6px;
    padding: 0 8px;
    line-height: 21px;
    font-size: 12px;
    background-color: $white;
    border: 1px solid $gray300;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 0;

    &s {
      display: flex;
      align-items: flex-end;
      margin: 0 0 3px auto;
    }

    > a {
      @include position(absolute, 0, 0, 0, 0);
    }

    &:first-child {
      z-index: 1;
    }

    &:last-of-type {
      position: static;
    }
    &--not-applicable {
      color: $gray500;
    }
    &--download {
      color: $white;
      background-color: $black;
      border-color: $black;
    }
  }

  &__logo {
    @include position(absolute, 20px, 15px);

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border: 1px solid $gray300;
    border-radius: 100%;
    box-sizing: border-box;

    > img {
      max-width: 70%;
      max-height: 50%;
    }
    &--full {
      > img {
        @include position(absolute, 0, null, null, 0);

        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none;
        max-height: none;
      }
    }
  }

  &__event {
    @include position(absolute, 0, 0, 0, 0);

    display: block;
  }
}
