.cm-new {
  display: block;
  overflow: hidden;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: $musinsaAccent;
  text-indent: -9999px;

  @at-root {
    // header 알림 영역
    .header__area .header__button & {
      @include position(absolute, 5px, 5px);
    }
    // footer 에서 사용
    .footer .footer__link > a > & {
      @include position(absolute, 3px, 1px);
    }
    // order 주문완료 & 환불완료 페이지 하단 알림 피드 팝업
    .order .popup-load-bottom & {
      @include position(absolute, 6px, 8px);

      width: 10px;
      height: 10px;
    }
  }
}
