.cm-bottom-sheet {
  @include resetFont; // NOTE 스토어 코드의 reset 상쇄 필요 시에만 적용
  // NOTE bottom sheet에서 별도 노치 영역 잡지 않음 - 강민 파트장님 확인
  // 노치 영역이 별도 필요할 경우 각 컨텐츠에서 적용

  @include position(fixed, null, null, 0, 0);

  width: 100%;
  border-radius: 28px 28px 0 0;
  background-color: $white;
  transform: translateY(calc(100% + 40px));
  transition: transform 0.4s cubic-bezier(0.38, 0.09, 0.12, 1);
  z-index: 9999;

  &::before {
    @include position(absolute, 15px, null, null, 50%);

    overflow: hidden;
    display: block;
    width: 60px;
    height: 4px;
    margin-left: -30px;
    border-radius: 2px;
    background-color: #d8d8d8;
    content: '';
  }

  &.active {
    transform: translateY(0);
  }
}
