@charset 'utf-8';

// font-face
@include font-face('Musinsa', 300, 'normal', 'musinsaot-Light', 'MusinsaLight');
@include font-face('Musinsa', 500, 'normal', 'musinsaot-Medium', 'MusinsaRegular');
@include font-face('Musinsa', 700, 'normal', 'musinsaot-Bold', 'musinsaot-Bold');

@include font-face('Musinsa Stencil', 300, 'normal', 'MusinsaLightStencil', 'MusinsaLightStencil');
@include font-face('Musinsa Stencil', 500, 'normal', 'MusinsaRegularStencil', 'MusinsaRegularStencil');

@include font-face('Musinsa Italic', 300, 'normal', 'musinsait-Light', 'musinsait-Light');
@include font-face('Musinsa Italic', 500, 'normal', 'musinsait-Medium', 'musinsait-Medium');
@include font-face('Musinsa Italic', 700, 'normal', 'musinsait-Bold', 'musinsait-Bold');

@include font-face('Musinsa Round', 500, 'normal', 'musinsard-Medium', 'musinsard-Medium');
@include font-face('Musinsa Round', 700, 'normal', 'musinsard-Bold', 'musinsard-Bold');

// TODO Pretendard font 적용 완료 시, 삭제 할 것!
// Pretendard font 추가
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;
  src: url("//static.msscdn.net/platform/fonts/Pretendard-Regular.eot");
  src: local("🙂"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Regular.woff2")
      format("woff2"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Regular.woff")
      format("woff"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Regular.ttf")
      format("truetype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Regular.svg#Pretendard-Regular")
      format("svg");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-style: normal;
  src: url("//static.msscdn.net/platform/fonts/Pretendard-Medium.eot");
  src: local("🙂"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Medium.woff2")
      format("woff2"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Medium.woff")
      format("woff"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Medium.ttf")
      format("truetype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Medium.svg#Pretendard-Medium")
      format("svg");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-style: normal;
  src: url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.eot");
  src: local("🙂"),
    url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.woff2")
      format("woff2"),
    url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.woff")
      format("woff"),
    url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.ttf")
      format("truetype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-SemiBold.svg#Pretendard-SemiBold")
      format("svg");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-style: normal;
  src: url("//static.msscdn.net/platform/fonts/Pretendard-Bold.eot");
  src: local("🙂"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Bold.woff2")
      format("woff2"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Bold.woff")
      format("woff"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Bold.ttf")
      format("truetype"),
    url("//static.msscdn.net/platform/fonts/Pretendard-Bold.svg#Pretendard-Bold")
      format("svg");
}
// TODO Pretendard font 적용 완료 시, 삭제 할 것! -- 여기까지

// IR
.blind {
  // 텍스트 숨김 처리 시에만 사용
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

body.fixed {
  // 팝업 오픈, 스켈레톤 시에만 사용
  overflow: hidden;
  @include position(fixed, 0, 0, null, 0);
}

// ANCHOR chip - label 용 color variables
$labelColors: (
  'default': $black,
  'clearance': $red1,
  'new': #ff9500,
  'limited': #cc4e63,
  'before': #ff797a,
  'musinsa': #18a286,
  'online': #1b8cb0,
  'standard': $black,
  'invitation': $black,
  'foreign': #7d9fc3,
  'parallel': #6c89a8,
  'terrace': #440099,
  'due': #0095dc,
  'terrace-sale': #8a4fbb,
  'delay': #cf0004,
  'raffle': $musinsaAccent,
  'stop': $oldGray5,
  'soldout': $oldGray5,
  'family-sale': #ff5d00,
  'event': #2930ff,
  'end-event': #999,
  'proceeding': $musinsaAccent,
  'sale': #ff0000,
  'end': $oldGray6,
  'gift': #ffc800,
  'campaign': $black,
  'campaign01': #ed0060,
  'campaign02': #00d47b,
  'campaign03': #5800ff,
  'campaign04': #ed00ec,
  'campaign05': #0091fa,
  'campaign06': #ff532d,
  'expected': $black,
  'rank': $white,
  'outlet': #b90000,
  'boutique': #0000ff,
  'directPurchase': #6c89a8,
  'newsemester': #ed00ec,
  'earth': #07bd3f,
  'plusdelivery': $black,
);

// ANCHOR chip - label backgrouindColor 할당
@each $class, $color in $labelColors {
  [class$='--state-#{$class}'] {
    background: $color;
  }
}

[class$='--state-plusdelivery'] {
  &::before {
    width: 22px;
    height: 26px;
    margin-right: 4px;
    background-position: center 1px;
    background-repeat: no-repeat;
    background-image: url(https://image.msscdn.net/musinsaUI/store/label/Ic22linePlusDelivery.svg?202304201431);
    content: '';
  }
}
